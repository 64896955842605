<template>
  <div class="d-flex" style="height:89vh;">
    <a-spin
      :indicator="indicator"
      v-if="loading"
      :tip="loadingMessage"
      class="m-auto"
    >
    </a-spin>

    <div
      v-else
      class="m-auto card p-4 w-50"
      :class="{ 'h-25': deviceReady, 'h-50': !deviceReady }"
    >
      <div class="d-flex flex-column h-100" v-if="deviceReady">
        <h4>Device Ready</h4>
        <h6>
          Your device is ready. Lets start by
          <a-button type="link" @click="gotoDefineProcedure"
            >Defining A Process</a-button
          >
        </h6>
      </div>
      <div class="d-flex flex-column" v-else>
        <a-descriptions
          class="mt-2"
          size="small"
          :colon="false"
          title="Looks like you haven't setup a device yet"
        >
          <a-descriptions-item span="3"
            >Click on the button below to download the
            installer.</a-descriptions-item
          >
        </a-descriptions>
        <installer-button
          class="d-inline-flex mr-auto mb-2"
          :buttonType="buttonType"
        />
        <a-descriptions
          class=""
          size="small"
          :colon="false"
          title="Follow these steps after the installation is complete."
        >
          <a-descriptions-item span="3" label="1."
            >Login to Apollo Pathfinder on the Desktop.</a-descriptions-item
          >
          <a-descriptions-item span="3" label="2."
            >Refresh your page.</a-descriptions-item
          >
        </a-descriptions>
        <a-spin
          :indicator="indicator"
          :tip="deviceLoading"
          class="m-auto"
          v-if="findingDevices"
        >
        </a-spin>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper-bundle.css';
import SwiperCore, { EffectCards } from 'swiper';
import AddNewTask from '../process/Task/AddNewTask.vue';
import tasks from '../../../../services/tasks';
import devices from '../../../../services/device';
import org from '../../../../services/organization';
import { mapGetters } from 'vuex';
import { useToast } from 'vue-toastification';
import InstallerButton from '../../../shared/Components/InstallerButton';
import { ref, defineComponent, h } from 'vue';
import { LoadingOutlined } from '@ant-design/icons-vue';

SwiperCore.use([EffectCards]);

const POC_DEPARTMENT = 'Evaluation Department';

export default {
  components: {
    InstallerButton,
  },
  inject: ['showToast'],
  setup() {
    const toast = useToast();
    const buttonType = ref('primary');
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '48px',
      },
      spin: true,
    });
    return { toast, buttonType, indicator };
  },
  async mounted() {
    const [error, data] = await tasks.fetchTasks(false);
    this.tasks = data;
    this.loadingMessage = 'Finding Departments';
    this.pollingAddDevice = setInterval(
      this.addDevice,
      this.pollingTimeInSec * 1000
    );
  },
  methods: {
    async addDevice() {
      const [error, departments] = await org.getAllDepartmentsOfOrg(
        this.organization,
        false
      );

      if (error) return;
      if (this.getAvailableAssignedDevices(departments) > 0)
        this.deviceReady = true;
      else if (departments.length <= 0) await this.createFirstDepartment();
      else this.departID = departments[0].id;

      await this.assignDeviceIfAvailable();
      this.pollingDevices = setInterval(this.assignDeviceIfAvailable, 3000);
      this.loading = false;
      clearInterval(this.pollingAddDevice);
    },
    async createFirstDepartment() {
      this.setLoadingMessage('No departments Found');
      this.showToast('info', 'Creating a department', 2000);
      const [errors, departInfo] = await org.createDepartment(
        this.organization,
        POC_DEPARTMENT,
        false
      );
      if (departInfo) {
        this.departID = departInfo.id;
        this.showToast('success', 'Department Created', 2000);
      } else if (errors) {
        this.showToast('error', 'Failed to create department', 2000);
      }
    },
    async assignDeviceIfAvailable() {
      this.findingDevices = true;
      let [error, device] = await devices.fetchAllDepartmentLessDevices(
        this.organization,
        false
      );
      if (device.length > 0) {
        const [err, res] = await devices.assignDeviceToDepartment(
          this.organization,
          device[0].id,
          { department: this.departID },
          false
        );
        if (res) {
          this.showToast('success', 'Device Found. Assigning it to Department');
          clearInterval(this.pollingDevices);
          this.pollingDevices = null;
          this.findingDevices = false;
          this.deviceReady = true;
        }
      }
    },
    setLoadingMessage(message) {
      this.loadingMessage = message;
    },
    gotoDeviceManagement() {
      this.$router.push({ path: '/user/device-management' });
    },
    gotoDefineProcedure() {
      this.$router.push({ path: '/user/process' });
    },
    getAvailableAssignedDevices(departments) {
      let totalDevices = 0;
      if (departments.length === 0) return 0;
      departments.forEach(
        (department) =>
          (totalDevices += department.devices.filter(
            (device) => department.name !== device.Serial_number
          ).length)
      );
      return totalDevices;
    },
  },
  unmounted() {
    clearInterval(this.pollingAddDevice);
  },
  computed: {
    ...mapGetters(['getTaskId', 'organization']),
  },
  data() {
    return {
      swiper: null,
      tasks: [],
      log: console.log,
      pollingAddDevice: null,
      pollingTimeInSec: 5,
      totalAssignedDevices: 0,
      devices: [],
      loading: true,
      loadingMessage: '',
      deviceLoading: 'Waiting for device',
      pollingDevices: null,
      findingDevices: false,
      departID: null,
      deviceReady: false,
    };
  },
};
</script>
<style>
.swiper {
  margin: auto;
  width: 80%;
  height: 80%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  background: #fff;
}

.instruction-list {
  font-size: 16px;
  font-weight: lighter;
  justify-content: center;
  align-items: center;
}
</style>
